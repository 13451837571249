jQuery(document).ready(function ($) {



    /*$('.selectBox').selectize({
     create: true,
     sortField: {
     field: 'text',
     direction: 'asc'
     },
     dropdownParent: 'body'
     });


     $('.menu').dropit();


     $( '.swipebox' ).swipebox();*/

    $('.mh-item').matchHeight();
    $('.logoRow__item').matchHeight();


    $('.navMain').dropit();




});


$(window).on("load", function () {
    $('.years').addClass('years-in');

    $('.pageHeading-top').addClass('pageHeading-top-in');
    $('.pageHeading-left').addClass('pageHeading-left-in');
    $('.pageHeading-right').addClass('pageHeading-right-in');
    $('.pageHeading-bottom').addClass('pageHeading-bottom-in');
    $('.pageHeading-fade').addClass('pageHeading-fade-in');



});



$( ".js-navTrigger" ).click(function() {
    $('body').addClass('body-nav-open');
});
$( ".js-navTrigger-close" ).click(function() {
    $('body').removeClass('body-nav-open');
});




/*jQuery( document ).ready(function( $ ) {

    var question = $('.js-accordion .js-question');
    var answer = $('.js-accordion .js-answer');

    $(question).click(function(){
        $(answer).slideUp('fast');
        $(this).parent().removeClass('js-accordion-open');

        if($(this).next().is(':hidden')){
            $(this).next().slideDown('fast');
            $(this).parent().addClass('js-accordion-open');
        }

    });

});*/

jQuery( document ).ready(function( $ ) {
    $('.iconRow__item').matchHeight();
});

